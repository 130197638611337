import { Button } from '@material-ui/core'
import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import HowBidPopup from '../../../Component/HowBiddingWorks'
import { Copyrightinfo, Logo, FooterLogo } from '../../../utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthContext from '../../../Product/context/auth/authContext'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
function Footer() {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const [hbid, setHBid] = React.useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)

    const forMobile = useMediaQuery('(max-width:1024px)')
    const isBidDepotProject = document.querySelector('body').classList.contains('BidAuction')

    return (
        <>
            {isBidDepotProject ? (
                <footer>
                    <div className="footerInner">
                        <div className="mobile-view-footer">
                            {/* Auctions Section */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Auctions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li>
                                            <NavLink activeClassName="active" to="/how_it_works">
                                                Pick Up Location
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/auctions">
                                                Browse All Auctions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/">
                                                Current Auctions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/">
                                                Upcoming Auctions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/">
                                                Past Auctions
                                            </NavLink>
                                        </li>
                                        <br />
                                        <Typography variant="h6" className="secondhalfTit">
                                            CONTACT BID AUCTION DEOPT
                                        </Typography>
                                        <li>
                                            <a
                                                href="mailto:support@bidauctiondepot.com"
                                                className="email-link"
                                            >
                                                support@bidauctiondepot.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+5139935252" className="phone-link">
                                                (513) 993-5252
                                            </a>
                                        </li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>

                            {/* Categories Section */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Categories</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Appliances
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Automotive
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Clothing &amp; Accessories
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Electronics
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Grocery
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Home &amp; Kitchen
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Patio, Lawn &amp; Garden
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Tools &amp; Home Improvement
                                            </NavLink>
                                        </li>
                                        <br />
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                <b style={{ color: '#ff6503' }}>
                                                    SHOP ALL CATEGORIES
                                                </b>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>

                            {/* Resources Section */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Resources</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li>
                                            <NavLink activeClassName="active" to="/how_it_works">
                                                How it Works
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/aboutus">
                                                About Us
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/contact_us">
                                                Contact Us
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/how_it_works">
                                                Product Conditions and Returns
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/privacy_policy">
                                                Privacy Policy
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/termsOfService">
                                                Terms &amp; Conditions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/faq">
                                                FAQ&apos;s
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/how_it_works">
                                                Anonymous Feedback Form
                                            </NavLink>
                                        </li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="footerLeft_Base">
                            <dl>
                                <dd className="navTitlt">Auctions</dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/contact_us">
                                        Pick Up Location
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/">
                                        Browse All Auctions
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/">
                                        Current Auctions
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/">
                                        Upcoming Auctions
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/">
                                        Past Auctions
                                    </NavLink>
                                </dd>
                                <dd style={{ height: '25px' }}></dd>
                                <dd className="secondhalfTit">CONTACT BID AUCTION DEPOT</dd>
                                <dd>
                                    <a
                                        href="mailto:support@bidauctiondepot.com"
                                        className="email-link"
                                    >
                                        support@bidauctiondepot.com
                                    </a>
                                </dd>
                                <dd>
                                    <a href="tel:+5139935252" className="phone-link">
                                        (513) 993-5252
                                    </a>
                                </dd>
                            </dl>
                            <dl>
                                <dd className="navTitlt">Categories</dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Appliances
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Automotive
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Clothing & Accessories
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Electronics
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Grocery
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Home & Kitchen
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Patio, Lawn & Garden
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/search">
                                        Tools & Home Improvement
                                    </NavLink>
                                </dd>
                                <dd className="secondhalfTit">
                                    <NavLink activeClassName="active" to="/search">
                                        SHOP ALL CATEGORIES
                                    </NavLink>
                                </dd>
                            </dl>
                            <dl>
                                <dd className="navTitlt">Resources</dd>
                                <dd style={{ height: '25px' }}></dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        How it Works
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        About Us
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/contact_us">
                                        Contact Us
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Product Conditions and Returns
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/privacy_policy">
                                        Privacy Policy
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        Terms &amp; Conditions
                                    </NavLink>
                                </dd>
                                {/* <dd>
                                    <NavLink activeClassName="active" to="/faq">
                                        FAQ&apos;s
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Anonymous Feedback Form
                                    </NavLink>
                                </dd> */}
                            </dl>
                        </div>
                        <div className="footerRight_Base">
                            <FooterLogo className="footerLogo" />
                            <span className="d-md-block">
                                <p className="text-center copyright">
                                    © Copyright 2024{' '}
                                    <span className="fc-name copy-right">Bid Auction Depot</span>
                                </p>
                            </span>
                            {/* <div className="socialButton_Base">
                                <a href="#">
                                    <img src="/assets/images/appStore.png" alt="AppStore Link" />
                                </a>
                                <a href="#">
                                    <img
                                        src="/assets/images/googleStore.png"
                                        alt="googleStore Link"
                                    />
                                </a>
                            </div> */}
                        </div>
                    </div>
                </footer>
            ) : (
                <footer className="footer-main-standard noPrint">
                    <div className="customContainer">
                        <div className="row" data-gutter="60">
                            <div className="col-12 ftrDtlCntnr">
                                <div className="footLogoCnt">
                                    <FooterLogo className="footerLogo" />
                                    <span className="d-md-block d-none">
                                        <Copyrightinfo />
                                    </span>
                                </div>
                                <div className="footerMisc">
                                    <ul
                                        id={
                                            global.storeDetails?.footer_theme == '#0d0c0c' ||
                                            global.storeDetails.footer_theme == '#000000'
                                                ? 'change-footer-hov-color'
                                                : ''
                                        }
                                        className="footerLinks"
                                    >
                                        {/* <li onClick={() => setHBid(true)}>
                                            <a className="cursorDecoy">How does bidding work</a>
                                        </li> */}
                                        <li>
                                            <NavLink activeClassName="active" to="/how_it_works">
                                                How It Works
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/termsOfService">
                                                Terms & Conditions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/privacy_policy">
                                                Privacy Policy
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/faq">
                                                FAQs
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/aboutus">
                                                About Us
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/contact_us">
                                                Contact Us
                                            </NavLink>
                                        </li>
                                        <Footernav />
                                    </ul>

                                    <ul className="footer-main-standard-social-list">
                                        {facebookLink && (
                                            <li className="facebook">
                                                <a
                                                    href={facebookLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Facebook"
                                                >
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </li>
                                        )}
                                        {twitterLink && (
                                            <li className="pinterest">
                                                <a
                                                    href={twitterLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Twitter"
                                                >
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        )}
                                        {googleLink && (
                                            <li className="instagram">
                                                <a
                                                    href={googleLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </li>
                                        )}
                                        {instagramLink && (
                                            <li className="instagram">
                                                <a
                                                    href={instagramLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="d-md-none d-block">
                        <Copyrightinfo />
                    </span>
                    <HowBidPopup open={hbid} handleClose={() => setHBid(false)} />
                </footer>
            )}
        </>
    )
}

export default Footer
